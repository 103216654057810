import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { gql } from "apollo-angular";
import { EnvCommonService } from "./envcommon.service";

@Injectable({
  providedIn: "root",
})
export class UpaymentService {
  userdatalist1: any[] = [];
  token: any;

  constructor(private apollo: Apollo,  private cookieService: EnvCommonService,
  ) {
    this.token = this.cookieService.getToken();
  }

  updatepayment(odid:string, payid:string) { 
    return this.apollo.mutate({
      mutation: gql`
        mutation upayment(
          $odid: String!
          $payid: String!
          $country_id: String
          $state_id: String,
          $token: String!,
        ) {
          upayment(
            odid: $odid
            payid: $payid
            country_id: $country_id
            state_id: $state_id
            token: $token
           ) {
            code
            message
            invno
          }
        }
      `,
      variables: {
        odid: odid,
        payid: payid,
        country_id: '77',
        state_id: '',
        token: this.token,
      },
    });
  }
}


